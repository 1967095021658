<template>
    <div class="job-card">
        <div class="card-thumbnail" v-lazy:background-image="job.banner_image">
            <div class="thumbnail__status thumbnail__status--danger" v-if="(job.is_expired && job.status == 2)">
                {{$t(`Expired`)}}
            </div>
            <div class="thumbnail__status thumbnail__status--warning" v-else-if="job.status == 1">{{$t(`Draft`)}}</div>
            <div class="thumbnail__status thumbnail__status--success" v-else-if="job.status == 2">{{$t(`Active`)}}</div>
            <div class="thumbnail__status thumbnail__status--info" v-else-if="job.status == 3">{{$t(`Archived`)}}</div>
            <div class="card-control">
                <router-link :to="generatePipelineUrl(job.slug, job.pipeline_view_mode)" v-if="options.pipeline && (canOrganizeCandidate || canManagementJob)" class="control-button control-button--primary">
                    <div class="control-button__icon">
                        <i class="eicon e-pipe"></i>
                    </div>
                    <span>{{$t(`Pipeline`)}}</span>
                </router-link>
                <router-link :to="{ name: 'job.candidates', params: { jobId: job.slug } }" v-if="options.candidates && canViewCandidate" class="control-button control-button--primary">
                    <div class="control-button__icon">
                        <i class="eicon e-users"></i>
                    </div>
                    <span>{{$t(`Candidates`)}}</span>
                </router-link>
                <router-link :to="{name: 'job.edit', params: {id: job.slug}}" v-if="options.edit && canManagementJob" class="control-button control-button--primary">
                    <div class="control-button__icon">
                        <i class="eicon e-document"></i>
                    </div>
                    <span>{{$t(`Edit`)}}</span>
                </router-link>
                <a class="control-button control-button--primary" href="#" v-if="options.share && canManagementJob && !job.is_expired" @click.prevent="openJobShareModal">
                    <div class="control-button__icon"><i class="eicon e-share"></i></div>
                    <span>{{$t(`share`)}}</span>
                </a>
                <a href="#" v-if="options.duplicate && canManagementJob" @click.prevent="duplicateJob()" class="control-button control-button--primary">
                    <div class="control-button__icon">
                        <i class="eicon e-duplicate"></i>
                    </div>
                    <span>{{$t(`Duplicate`)}}</span>
                </a>
                <a href="#" v-if="options.delete && canManagementJob" @click.prevent="deleteJob()" class="control-button control-button--danger">
                    <div class="control-button__icon">
                        <i class="eicon e-delete"></i>
                    </div>
                    <span>{{$t(`Delete`)}}</span>
                </a>
                <div class="dropdown job-control-more" v-on-click-away="hideMore" v-if="options.more">
                    <button class="control-button control-button--primary" @click.prevent="isShowMoreMenu = !isShowMoreMenu">
                        <div class="control-button__icon">
                            <i class="eicon e-plus"></i>
                        </div>
                        <span>{{$t(`more`)}}</span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-center" aria-labelledby="dropdownMenuButton" :class="{'show' : isShowMoreMenu}">
                        <a class="dropdown-item text-capitalize" v-if="options.more.view" :href="job.job_link" target="_blank">
                            <div class="dropdown-icon"><i class="eicon e-eye-1"></i></div>
                            {{$t(`view`)}}
                        </a>
                        <router-link :to="{name: 'job.edit', params: {id: job.slug}}" v-if="options.more.edit && canManagementJob" class="dropdown-item text-capitalize">
                            <div class="dropdown-icon"><i class="eicon e-document"></i></div>
                            {{$t(`Edit`)}}
                        </router-link>
                        <a class="dropdown-item" href="#" @click.prevent="checkPublishedJob()" v-show="options.more.duplicate && canPublishJob && job.is_expired">
                            <div class="dropdown-icon"><i class="eicon e-pencil"></i></div>
                            {{ $t("republish")}}
                        </a>
                        <a class="dropdown-item" href="#" @click.prevent="duplicateJob()" v-show="options.more.duplicate && canManagementJob">
                            <div class="dropdown-icon"><i class="eicon e-duplicate"></i></div>
                            {{ $t("Duplicate")}}
                        </a>
                        <a class="dropdown-item delete" href="#" @click.prevent="deleteJob()" v-show="options.more.delete && canManagementJob">
                            <div class="dropdown-icon"><i class="eicon e-delete"></i></div>
                            {{$t(`Delete`)}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-content">
            <div class="card-content__left">
<!--                <router-link v-if="job.status == 2 || job.status == 3" :to="{ name : 'job.pipeline', params: { jobId: job.slug } }" disabled=""><h3 class="card-title">{{ job.title }}</h3></router-link>-->
                <h3 class="card-title" v-if="job.status == 2 || job.status == 3">
                    <router-link :to="{ name : 'job.pipeline', params: { jobId: job.slug } }" disabled="">{{ job.title }}</router-link>
                </h3>
                <h3 class="card-title" v-else>{{ job.title }}</h3>
                <h4 class="card-sub-title">{{ job.category.name }}</h4>
                <div class="card-info-group">
                    <p class="card-info text-capitalize">{{ $t("post date")}}: {{ job.posted_at }}</p>
                    <p class="card-info text-capitalize">{{ $t("Expiry Date")}}: {{ job.expire_at }}</p>
                </div>
            </div>
            <div class="card-content__right" v-if="job.applicant_count > 0">
                <p>({{ job.applicant_count }}) {{ $t("Applied")}}</p>
                <div class="applicants__img" v-if="job.applicants.length > 0">
                    <img v-for="applicant in job.applicants"
                         :src="applicant.image"
                         :alt="applicant.name"
                         @error="loadApplicantErrorImage(applicant)"
                    />
                    <p class="Applicants" v-if="moreApplicants">{{ moreApplicants }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from "vue";
    import client from "../../app/api/Client";
    import {EventBus} from "../../extra/event-bus";
    import {OPEN_JOB_SHARE_MODAL} from "../../constants/events";
    import VueLazyLoad from  "vue-lazyload";
    import {vueJsLazyLoad as vueJsLazyLoadOptions} from "../../config/options";
    import {canManagementJob, canOrganizeCandidate, canPublishJob, canViewCandidate} from "../../config/permission";
    import {JOB} from "../../extra/constants";
    import {LOAD_MESSAGE_NOTIFICATION} from "../../constants/action-type";
    Vue.use(VueLazyLoad, vueJsLazyLoadOptions);


    export default {
        props: [
            'job',
            'options'
        ],
        components: {

        },
        data() {
            return {
                currentJob: {},
                isShowMoreMenu: false
            }
        },
        methods: {
            hideMore() {
                this.isShowMoreMenu = false;
            },

            generatePipelineUrl(jobId, pipelineToggler = 1) {
                if(pipelineToggler === 2) {
                    return {
                        name : 'job.pipeline.board',
                        params: {
                            jobId
                        }
                    }
                } else {
                    return {
                        name : 'job.pipeline',
                        params: {
                            jobId
                        }
                    }
                }
            },

            async deleteJob() {
                let message = {
                    title: this.$t("Confirmation"),
                    body: this.$t("Are you sure, you want to delete this job?")
                };
                this.$dialog.confirm(message).then(() => {
                    client().delete(`job/${this.job.slug}/delete`)
                        .then(({data: {data, message}}) => {
                            this.$toast.success(this.$t(message));
                            this.$emit('jobDeleted', this.job);
                            this[LOAD_MESSAGE_NOTIFICATION]();
                        }).catch(({response: {data, status}}) => {
                        this.$toast.error(data.message);
                    });
                })
            },
            duplicateJob() {

                if (this.canManagementJob === false) {
                    this.showNotPermittedMessage();
                    return false;
                }

                let message = {
                    title: this.$t("Confirmation"),
                    body: this.$t('Are you sure you want to duplicate this job?')
                };
                this.$dialog.confirm(message).then(() => {
                    client().get(`job/${this.job.slug}/duplicate`).then(({data: {data, message}}) => {
                        this.$toast.success(this.$t(message));
                        this.jobEdit(data);
                    }).catch(({response: {data}}) => {
                        this.$toast.error(data.message);
                    });
                })
            },
            jobEdit(data) {
                this.$router.push({
                    name: 'job.edit',
                    params: {id: data.slug}
                })
            },
            copyUrl() {
                _helper.copyText(this.job.job_link);
                this.$toast.success(this.$t(`Copied`));
            },
            async loadRecentCandidates() {
                try {
                    let {data: {data: {applicants}}} = await client().get(`job/${this.job.id}/short-view`);
                    // this.applicants = applicants;
                } catch (e) {

                }
            },
            openJobShareModal() {
                EventBus.$emit(OPEN_JOB_SHARE_MODAL, this.job);
            },
            checkPublishedJob() {
                try {
                    let message = {
                        title: this.$t("Confirmation"),
                        body: this.$t(`Are you sure, you want to re-publish and set expire date to 30 days from now?`)
                    };

                    let dialogConfig = {
                        okText: this.$t('yes'),
                        cancelText: this.$t('no'),
                    };

                    this.$dialog.confirm(message, dialogConfig).then(() => {
                        client().post(`/job/${this.job.slug}/change-status`, {
                            status: JOB.STATUS.PUBLISHED,
                            change_expire_date: true
                        }).then(({data}) => {
                            if (data.status === 'SUCCESS') {
                                this.$toast.success(this.$t('Job re-published.'));
                                this.$emit('jobPublished');

                            } else {
                                this.$toast.error(this.$t("Something went wrong"));
                                this.$router.push({name: 'job.edit', params: {id: this.job.id}});
                            }
                        }).catch(err => {
                            if (err.response.status === 422) {
                                this.errors = err.response.data.message;
                                this.$toast.error('Oops! Please check your input');
                            } else {
                                this.$toast.error(err.response.data.message);
                                this.$router.push({name: 'job.edit', params: {id: this.job.id}});
                            }
                        });
                    });
                } catch (e) {
                }
            }
        },

        computed: {
            totalApplicants() {
                return this.job.applicant_count;
            },

            moreApplicants() {
                if (this.totalApplicants > 4) {
                    return this.job.applicant_count - 4;
                } else {
                    return 0;
                }
            },

            canOrganizeCandidate() {
                return canOrganizeCandidate();
            },

            canViewCandidate() {
                return canViewCandidate();
            },

            canManagementJob() {
                return canManagementJob();
            },

            canPublishJob() {
                return canPublishJob();
            }
        }
    }
</script>
